// Eigenes CSS
import '../css/style.scss';

// Einfach mal hallo sagen
//console.log('Hallo Welt');

import 'jquery/dist/jquery';
import 'superfish/src/js/superfish';
//import 'mmenu-js/src/mmenu';
//import 'slideout/dist/slideout';
import 'zeynepjs';
import 'jquery-match-height/dist/jquery.matchHeight-min';
//import 'snapjs';
//const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);
//import 'snapsvg';
//import Snap from 'snapsvg';
//import 'swiper/swiper-bundle';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
import 'jquery-ui/ui/position';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';

import 'jquery-is-in-viewport/dist/isInViewport.jquery.min';
//import 'in-viewport';

import 'isotope-layout/dist/isotope.pkgd';






//import 'resize-observer/dist/resize-observer';

//import Chart from 'chart.js/auto';

// import Chart from 'chart.js/auto';



//var fd_page = document.getElementsByTagName("html")[0];
//fd_page.style.opacity = "0";

(function( $ ){
    $.fn.myfunction = function() {
       //console.log('hello world');
       return this;
    }; 
 })( jQuery );


/****************  INIT  *****************************/

var App = function ( ) {

    function initPageload() {
        //console.log("Pageload");
        //jQuery('html').css("opacity", "0.8");
        jQuery('html').fadeTo(300, 1);
    }


    function initSuperfish() {
        //console.log("Superfish");
        //jQuery('ul.wpb-sf-menu').css('background-color','#ffff00');
        //jQuery('.fd-header-main-navigation ul').css('background-color','#ddff00');
        //jQuery('#wpb--menu').superfish({});
        
        jQuery('ul.sf-menu').superfish({
            delay:       1000,                            // one second delay on mouseout
            animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
            speed:       'fast',                          // faster animation speed
            onInit: function () {
                //console.log("SF Init");
                jQuery('.wpb-sub-menu-inner').addClass('hidden');
            },
            onShow: function () {
                //console.log("SF Init");
                jQuery(this).removeClass('hidden');
            },
            onBeforeHide: function () {
                //console.log("SF Init");
                jQuery(this).addClass('hidden');
            },
            cssArrows: false
            
        });
    }

    function initMmenu() {
        new Mmenu( "#my-menu" );
        //, {
            // options
            /*
            "extensions": [ 
                "theme-dark",
                "pagedim-black"
            ],
            */
           /*
            navbar: {
                title: "title" // "10.000 Schritte Düsseldorf"
            }
        }, {
            // configuration
            offCanvas: {
                page: {
                    selector: "#ukd10k-page"
                }
            }
        });
        */
    }

    function initShrinkedHeader() {
        let shrink_active = false;
        jQuery(window).scroll(function () {
            var windowwidth = jQuery(window).width();
            //console.log("windowwidth: " + windowwidth);
         //   if (windowwidth > 750) {
             /*
                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-header').addClass('header-preshrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-preshrinked');
                }
                */
                if (jQuery(window).scrollTop() > 133) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    //console.log("shrinked");
                } else if (jQuery(window).scrollTop() < 100) {
                    jQuery('#fd-header').removeClass('header-shrinked');
                }

                if (jQuery(window).scrollTop() > 133) {
                    jQuery('#fd-page').addClass('main-shrinked');
                } else if (jQuery(window).scrollTop() < 100) {
                    jQuery('#fd-page').removeClass('main-shrinked');
                }



                /*
                if (jQuery(window).scrollTop() > 35) {
                    shrink_active = true;
                    
                } else if (jQuery(window).scrollTop() < 5) {
                    shrink_active = false;
                    
                }
                if ( shrink_active ) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    jQuery('#fd-page').addClass('main-shrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-shrinked');
                    jQuery('#fd-page').removeClass('main-shrinked');
                }
                */
          //  }
        });

    }

    function initRefreshAfterResize() {
        var dwidth = jQuery(window).width();
        jQuery(window).bind('resize', function(e){
            var wwidth = jQuery(window).width();
            if(dwidth!==wwidth){
            dwidth = jQuery(window).width();
            if (window.RT) clearTimeout(window.RT);
            window.RT = setTimeout(function(){
                this.location.reload(false); /* false to get page from cache */
            }, 1000);
        }
        });
    }

    function initMatchHeight() {
        //console.log("match Height");
        //jQuery(' .news-teaser-3col > div ').matchHeight({byRow: true});
        //jQuery(' .post ').matchHeight({byRow: true});
        //jQuery(' .post-txt h3.txt ').matchHeight({byRow: true});
        jQuery(' .post-txt ').matchHeight({byRow: true});
        jQuery(' .prod_table_body_row span').matchHeight({byRow: true});
        let span1height = jQuery(' prod_table_body_row .span_1 ').height();
        jQuery(' prod_table_body_row .span_6 ').height(span1height);
    }

    function initTooltips() {
        jQuery(function () {
            jQuery('[data-toggle="tooltip-right"]').tooltip({placement: 'right', html: true});
            //jQuery('[data-toggle="tooltip-top"]').tooltip({placement: 'top', html: true, trigger: 'hover'});
            //jQuery('[data-toggle="tooltip-left"]').tooltip("show");
        })
    }


    function initSwiperNews() {
        var newsSwiper = new Swiper('.posts-row', {
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
              },
            loop: true,
            grabCursor: true,
            on: {
                init: function () {
                  console.log('swiper initialized');
                  initMatchHeight();
                },
            },
        })
    }


    function initCookiesreopen() {
        jQuery('.cookiesreopen a').attr("href","javascript: Cookiebot.renew()");
    }


    function initCF7Styling() {
        var spans = document.getElementsByClassName("wpcf7-form-control-wrap");
        for(var i=0;i<spans.length;i++)
        {
            var input = spans[i].nextSibling;
            if(input != null)
            {
                if(input.tagName == "LABEL")
                {
                    spans[i].appendChild(input);
                }   
            }   
        }

        let dstext = jQuery(".wpcf7-checkbox .wpcf7-list-item-label").text();
        dstext = dstext.replace("Datenschutzerklärung", "<a href='/datenschutz/'>Datenschutzerklärung</a>");
        console.log(dstext);
        jQuery(".wpcf7-checkbox .wpcf7-list-item-label").html(dstext);
    }




    function initZeynep() {
        var zeynep = jQuery('.zeynep').zeynep({
            load: function (element, options) {
                //console.log('zeynepjs menu is successfully loaded')
              },
            onClosed: function () {
              // enable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "");
        
              console.log('the <a href="https://www.jqueryscript.net/tags.php?/side menu/">side menu</a> is closed.');
            },
            onOpened: function () {
              // disable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "pointer-events: none;");
        
              console.log('the side menu is opened.');
            }
            
        });

        jQuery(".mburger").click(function () {
            if (jQuery("html").hasClass("zeynep-opened")) {
              zeynep.close();
            } else {
              zeynep.open();
            }
        });

        jQuery(".zeynep-overlay").click(function () {
            zeynep.close();
        });
        jQuery("#mobileMenuClose-inner").click(function () {
            zeynep.close();
        });
    }


    function initSwiperSlider() {
        var swiper = new Swiper(".fdSwiper", {
            modules: [Navigation, Pagination, Autoplay, Lazy],
            loop: 'true',
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: 'true',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            //autoplay: {
            //    delay: 4000,
            //},
        });
    }

    function initSwiperSiegellogos() {
        var swipersiegellogos = new Swiper("#swipersiegellogos", {
            modules: [Navigation, Pagination, Autoplay, Lazy],
            loop: 'true',
            slidesPerView: 4,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                480: {
                  slidesPerView: 3,
                  spaceBetween: 10
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 10
                  }
              },
            grabCursor: true,
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: 'true',
            },
            //navigation: {
            //    nextEl: '.swiper-button-next',
            //    prevEl: '.swiper-button-prev',
            //  },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    }

    function initSwiperProduktHero() {
        var swiperprodukthero = new Swiper("#produktheroimages", {
            modules: [Navigation, Pagination, Autoplay, Lazy],
            loop: 'true',
            slidesPerView: 3,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  }
              },
            grabCursor: true,
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            //pagination: {
            //    el: ".swiper-pagination",
            //    clickable: 'true',
            //},
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    }

    
            

    function initHeroSwiperSlider() {
        var swiper = new Swiper(".heroSwiper", {
            modules: [Pagination, Autoplay, Lazy],
            loop: 'true',
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: 'true',
            },
            autoplay: {
                delay: 4000,
            },
        });
    }

    

    function LottieScrollTrigger(vars) {
        let playhead = {frame: 0},
            target = gsap.utils.toArray(vars.target)[0],
            speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
            st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
            animation = lottie.loadAnimation({
                container: target,
                renderer: vars.renderer || "svg",
                loop: false,
                autoplay: false,
                path: vars.path
            });
        for (let p in vars) { // let users override the ScrollTrigger defaults
            st[p] = vars[p];
        }
        animation.addEventListener("DOMLoaded", function() {
            gsap.to(playhead, {
          duration: vars.duration || 0.5,
          delay: vars.delay || 0,
                frame: animation.totalFrames - 1,
                ease: vars.ease || "none",
                onUpdate: () => animation.goToAndStop(playhead.frame, true),
                scrollTrigger: st
            });
        // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
        ScrollTrigger.sort();
        ScrollTrigger.refresh(); 
        });
      return animation;
    }
    /*
    if ( jQuery("body").hasClass("elretiro-template") ) {
        LottieScrollTrigger({
        target: "#testlottie",
        path: '/content/themes/elretiro/src/img/lottie/lage_01.json',
        speed: 'medium',
        scrub: 2 // seconds it takes for the playhead to "catch up"
        // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
    });
    }
    

    function init_fd_lottie() {
        console.log("lottie");
        var animation = bodymovin.loadAnimation({
            container: document.getElementById('fdlc'),
            path: '/content/themes/elretiro/src/img/lottie/lage_01.json',
            renderer: 'svg',
            loop: false,
            autoplay: false,
            name: "FD Animation",
            rendererSettings: {
                id: 'fdlottieid',
                className: 'fdlottieclass',
                
            }
        });
    }
    */

    
    function dreher3() {
        var Cont={val:0} , NewVal = 100 ;

        TweenMax.to(Cont,4,{val:NewVal,roundProps:"val",onUpdate:function(){
        jQuery('#Counter1').text(Cont.val + '%');
        }});
    }


    function initCounter(vars) {
        let stopzahlendreher = false;
        //console.log("initCounter");

        jQuery('.iconcounter-counter').each(function( index ) {
            let $this = jQuery(this);
            let count_to = $this.text();
            let numberformat = $this.data("numformat");
            $this.text(0);
            //console.log( index + ": " + count_to );

            const offset = -50 - 50 * index;
            jQuery( this ).isInViewport(function (status) {
                if (status === 'entered') {
                    
                    $this.addClass('throw-confetti');
                    var Cont={val:0} , NewVal = count_to;
                    TweenMax.to(Cont,3,{val:NewVal,roundProps:"val",onUpdate:function(){
                        if ( numberformat === "plain" ) {
                            $this.text(Cont.val);
                        } else {
                            $this.text(Intl.NumberFormat('de-DE').format(Cont.val));
                        }
                    }});
                }
                if (status === 'leaved') {
                    jQuery(this).removeClass('throw-confetti');
                }
            }, offset)
          });
        

        /*
        jQuery("#Counter1:in-viewport").function(px) {
            console.log( px ); // `px` represents the amount of visible height
            thisHeight = $(this).outerHeight() - 1;
            if(px > 0) {
                if (!stopzahlendreher) {
                    stopzahlendreher = true;
                    //dreher1();
                    //dreher2();
                    dreher3();
                }
            }else{
                if (stopzahlendreher) {
                    stopzahlendreher = false;
                }
            }
        }; 
        */

        
    
}
function initMoreAccordions() {
    //console.log("initMoreAccordions");
    jQuery('.fd_layout--2-Col-50-50-img-txt---Accordion-Section .fd_btn').click(function() {
        jQuery(this).toggleClass('fd_btn--active');
        jQuery(this).parent().parent().parent().parent().parent().parent().parent().parent().find('.more-content').toggleClass('fd_accordion--active').fadeToggle('fast');
    });
    jQuery('.fd_layout--2-Col-50-50-txt-img---Accordion-Section .fd_btn').click(function() {
        jQuery(this).toggleClass('fd_btn--active');
        jQuery(this).parent().parent().parent().parent().parent().parent().parent().parent().find('.more-content').toggleClass('fd_accordion--active').fadeToggle('fast');
    });
}

function initProdTableImageToggle() {
    //console.log("initProdTableImageToggle");
    jQuery('.prod_table_body_row_img').hide();
    jQuery('.prod_table_body_row').click(function() {
        //console.log("prod_table_body_row clicked");
        let row_id = jQuery(this).data("row_id");
    
        //console.log("row_id: " + row_id);
        // jQuery('.prod_table_body_row').not(this).removeClass('active');
        jQuery(this).toggleClass('active');
        //jQuery('.prod_table_body_row_img').removeClass('active');
        jQuery('#prod_table_body_row_img_' + row_id).toggleClass('active');
        jQuery('#prod_table_body_row_img_' + row_id).animate({height: "toggle", opacity: "toggle"}, 300);
    });
}


  function initSidemenu() {
    //li.treeview a > span
    //console.log("initSidemenu");
    jQuery('li.treeview > a > span').click(function(event){
        event.preventDefault();
        console.log("Event prevented by span");
        //jQuery(this).parent().next('ul.treeview-menu').css('display','block');
        jQuery(this).parent().next('ul.treeview-menu').toggle('fast');
        jQuery(this).parent().parent().toggleClass('active');
    });
    jQuery('li.treeview > ul.treeview-menu > li > a > span').click(function(event){
      event.preventDefault();
      console.log("Event prevented by span");
      //jQuery(this).parent().next('ul.treeview-menu').css('display','block');
      jQuery(this).parent().next('ul.produkte').toggle('fast');
      jQuery(this).parent().parent().toggleClass('active');
  });
}

function initIsotope() {
    console.log("initIsotope");
    var $grid = jQuery('.produkte-teaser').isotope({
        // options
        itemSelector: '.produkt-teaser-item',
        layoutMode: 'fitRows'
      });

    var filters = {};
    // filter items on button click
    
    jQuery('.filter-button-group').on( 'click', 'button', function() {
        /*
        var filterValue = jQuery(this).attr('data-filter');
        console.log("filterValue: " + filterValue);
        $grid.isotope({ filter: filterValue });
        */
        jQuery(this).parent().find('.is-checked').removeClass('is-checked');
        jQuery(this).toggleClass('is-checked');
        var $this = jQuery(this);
        // get group key
        var $buttonGroup = $this.parents('.filter-button-group');
        var filterGroup = $buttonGroup.attr('data-filter-group');
        // set filter for group
        filters[ filterGroup ] = $this.attr('data-filter');
        // combine filters
        var filterValue = concatValues( filters );
        console.log("filterValue: " + filterValue);
        $grid.isotope({ filter: filterValue });
    });

    // flatten object by concatting values
    function concatValues( obj ) {
        var value = '';
        for ( var prop in obj ) {
        value += obj[ prop ];
        }
        return value;
    }
}

function initFilter() {
    //console.log("initFilter");
    jQuery('.filter_body').hide();
    jQuery('.filter').on( 'click', 'div.filter_head', function() {
        console.log("Click Filter-Head");
        jQuery(this).toggleClass('active').parent().find('.filter_body').toggleClass('active').slideToggle('fast');
    });
}


function headerSearchInput() {
    jQuery('#fd-header-meta-nav-search a').click(function(event){
        console.log("Header Search Clicked");
      jQuery('#header-search-input').toggle();
    });
  }


    return {
        init: function () {
            initPageload();
            initSuperfish();
            initSidemenu();
            //initMmenu(); 
            ////initRefreshAfterResize();
            initShrinkedHeader();
            initMatchHeight();
        //    initSwiperNews();
        //    initCF7Styling();
            ////initCookiesreopen();
            initTooltips();
            initZeynep();
            initSwiperSiegellogos();
            initSwiperSlider();
            initHeroSwiperSlider();
            initSwiperProduktHero();
        //  init_fd_lottie();
        //  LottieScrollTrigger();
            initCounter();
            initMoreAccordions();
            initProdTableImageToggle();
            initIsotope();
            initFilter();
            headerSearchInput();
        }
    }
}( );


jQuery(document).ready(function () {
    //console.log('ready  ??');
    App.init();
});



